<template>
    <ion-page>
        <ion-toolbar
            style="--background: #03a9f4; color: white; --min-height: 36px"
            class="custom-toolbar"
        >
            <div class="row align-center">
                <slot name="before">
                    <div class="shrink">
                        <DxButton
                            type="default"
                            icon="mdi mdi-chevron-left"
                            class="ml-1"
                            @click="$router.go(-1)"
                        />
                    </div>
                </slot>
                <slot name="center">
                    <div class="grow text-xs-center">BÁN VÉ GHẾ PHỤ</div>
                </slot>
                <slot name="after">
                    <div class="shrink" style="opacity: 0">
                        <DxButton icon="mdi mdi-filter-variant" type="default" />
                    </div>
                </slot>
            </div>
        </ion-toolbar>

        <ion-content :scroll-events="false">
            <div class="px-2 py-3 mb-5">
                <DxValidationGroup ref="formValidation">
                    <div class="row justify-center font-16 font-medium mb-1">
                        Thông tin vé
                    </div>
                    <div class="px-2">
                        <div class="row">Vị trí chỗ (VD: G-1; G-2;...)</div>
                        <div class="row mb-4 px-2">
                            <div class="xs12">
                                <DxTagBox
                                    ref="ViTriCho"
                                    labelMode="floating"
                                    v-model="TenCho"
                                    :searchEnabled="true"
                                    :acceptCustomValue="true"
                                    :showClearButton="true"
                                    :showSelectionControls="true"
                                    styling-mode="underlined"
                                    valueChangeEvent="keyup"
                                    :onKeyUp="onKeyUpTenCho"
                                    :onCustomItemCreating="onCustomItemCreating"
                                    validationMessageMode="always"
                                ></DxTagBox>
                                <!-- <DxTextBox
                                    v-model="TenCho"
                                    placeholder="VD: G-1; G-2;..."
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    :tabIndex="1"
                                >
                                    <DxValidator ref="">
                                        <DxStringLengthRule
                                            :max="128"
                                            message="Tên các chỗ không được vượt quá 128 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox> -->
                            </div>
                        </div>

                        <div class="row">Số lượng vé (*)</div>
                        <div class="row mb-4 px-2">
                            <div class="xs12">
                                <DxNumberBox
                                    v-if="TenCho.length > 0"
                                    :value="TenCho.length"
                                    placeholder="Nội dung"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    labelMode="floating"
                                    class="xs6 ml-12"
                                    :show-spin-buttons="true"
                                    :readOnly="true"
                                >
                                </DxNumberBox>
                                <DxNumberBox
                                    v-else
                                    v-model="SoLuongVe"
                                    validationMessageMode="always"
                                    :tabIndex="2"
                                    format=",##0"
                                    :step="1"
                                    :showSpinButtons="true"
                                    styling-mode="underlined"
                                    valueChangeEvent="keyup"
                                >
                                    <DxValidator ref="refValidateSoLuong">
                                        <DxNumericRule message="Số lượng phải là số!" />
                                        <DxRangeRule
                                            :min="1"
                                            :message="`Số lượng không được nhỏ hơn 1!`"
                                        />
                                        <DxRangeRule
                                            :max="10"
                                            :message="`Số lượng không được vượt quá 10!`"
                                        />
                                    </DxValidator>
                                </DxNumberBox>
                            </div>
                        </div>

                        <div class="row">Giá vé (*)</div>
                        <div class="row mb-4 px-2">
                            <div class="xs12">
                                <DxNumberBox
                                    v-model="GiaVe"
                                    :show-spin-buttons="true"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    format=",##0đ"
                                    :min="0"
                                    :step="1000"
                                    :readOnly="!checkSuaGiaVe"
                                    valueChangeEvent="keyup"
                                    :tabIndex="3"
                                >
                                    <!-- :onFocusOut="onFocusOutGiaVe" -->
                                    <!-- :onKeyUp="onChangeGiaVe" -->
                                    <DxValidator ref="refValidateGiaVe">
                                        <DxRequiredRule message="Vui lòng nhập giá vé!" />
                                        <DxRangeRule
                                            :max="2147483647"
                                            message="Giá vé không được vượt quá 2,147,483,647!"
                                        />
                                        <DxRangeRule
                                            :min="1"
                                            message="Giá vé không được nhỏ hơn 1!"
                                        />
                                    </DxValidator>
                                </DxNumberBox>
                            </div>
                        </div>

                        <div
                            class="row"
                            v-if="
                                ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                    $t(
                                        'MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru',
                                    )
                                ] == 'true'
                            "
                        >
                            Giá vé sau giảm trừ (*)
                        </div>
                        <div
                            class="row mb-4 px-2"
                            v-if="
                                ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                    $t(
                                        'MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru',
                                    )
                                ] == 'true'
                            "
                        >
                            <div class="xs12">
                                <DxNumberBox
                                    v-model="GiaVeSauGiamTru"
                                    :show-spin-buttons="true"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    format=",##0đ"
                                    :min="0"
                                    :step="1000"
                                    :readOnly="!checkSuaGiaVe"
                                    valueChangeEvent="keyup"
                                    :tabIndex="3"
                                >
                                    <DxValidator ref="refValidateGiaVe">
                                        <DxRequiredRule message="Vui lòng nhập giá vé!" />
                                        <DxRangeRule
                                            :max="model.GiaVe"
                                            message="Không được vượt quá giá vé gốc!"
                                        />
                                        <DxRangeRule
                                            :min="1"
                                            message="Giá vé không được nhỏ hơn 1!"
                                        />
                                        <DxRangeRule
                                            :max="2147483647"
                                            message="Giá vé không được vượt quá 2,147,483,647!"
                                        />
                                    </DxValidator>
                                </DxNumberBox>
                            </div>
                        </div>

                        <div class="row justify-space-between align-center">
                            <div class="font-16">Tổng tiền</div>
                            <div class="font-medium font-20 color-success">
                                <span class="mr-2">=</span>
                                {{ $MoneyFormat(TongTien) }}đ
                            </div>
                        </div>
                        <div
                            class="row justify-space-between align-center mb-2"
                            v-if="
                                ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                    $t(
                                        'MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru',
                                    )
                                ] == 'true'
                            "
                        >
                            <div class="font-16">Tổng tiền sau giảm trừ</div>
                            <div class="font-medium font-20 color-warning">
                                <span class="mr-2">=</span>
                                {{ $MoneyFormat(TongTienSauGiamTru) }}đ
                            </div>
                        </div>
                    </div>

                    <div class="row justify-center font-16 font-medium mb-1">
                        Thông tin chuyến đi
                    </div>
                    <div class="px-2">
                        <div class="row">Biển kiểm soát</div>
                        <div class="row mb-4 px-2">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="BienKiemSoat"
                                    placeholder="VD: 20A-01234"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    :tabIndex="4"
                                >
                                    <DxValidator ref="">
                                        <DxStringLengthRule
                                            :max="10"
                                            message="Biển kiểm soát không được vượt quá 10 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>

                        <div class="row">Giờ xuất bến dự kiến</div>
                        <div class="row mb-4 px-2">
                            <div class="xs12">
                                <DxDateBox
                                    v-model="GioXuatBenDuKien"
                                    validationMessageMode="always"
                                    displayFormat="HH:mm dd/MM/yyyy"
                                    dropDownButtonTemplate="customIcon"
                                    type="datetime"
                                    :tabIndex="5"
                                    :useMaskBehavior="true"
                                    ref="refTab1"
                                    styling-mode="underlined"
                                >
                                    <template #customIcon="{}">
                                        <DxButton icon="mdi mdi-calendar" />
                                    </template>
                                </DxDateBox>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-center font-16 font-medium mb-1">
                        Thông tin người mua
                    </div>
                    <div class="px-2">
                        <div class="row">Họ tên người mua</div>
                        <div class="row mb-4 px-2">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="HoVaTen"
                                    placeholder="VD: Nguyễn Văn A"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    :tabIndex="6"
                                >
                                    <DxValidator ref="">
                                        <DxStringLengthRule
                                            :max="128"
                                            message="Họ tên không được vượt quá 128 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>

                        <div class="row">Số điện thoại</div>
                        <div class="row mb-4 px-2">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="SoDienThoai"
                                    placeholder="VD: 0912345678"
                                    validationMessageMode="always"
                                    mode="tel"
                                    :tabIndex="7"
                                    styling-mode="underlined"
                                    :onKeyUp="onKeyUpSoDienThoai"
                                    valueChangeEvent="keyup"
                                >
                                    <DxValidator ref="refValidateSoDienThoai">
                                        <DxStringLengthRule
                                            :max="13"
                                            message="Số điện thoại không được vượt quá 13 ký tự!"
                                        />
                                        <DxNumericRule
                                            message="Số điện thoại phải là số!"
                                        />
                                        <DxRangeRule
                                            :min="0"
                                            message="Số điện thoại phải là số!"
                                        />
                                        <DxPatternRule
                                            :pattern="phonePattern"
                                            message="Sai định dạng số điện thoại!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>

                        <div class="row">Email</div>
                        <div class="row mb-4 px-2">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="Email"
                                    placeholder="VD: google@gmail.com"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    :tabIndex="8"
                                >
                                    <DxValidator>
                                        <DxEmailRule
                                            message="Định dạng Email không hợp lệ"
                                        />
                                        <DxStringLengthRule
                                            :max="128"
                                            message="Email không được vượt quá 128 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>
                    </div>
                </DxValidationGroup>

                <div class="row px-2 mb-3 align-center">
                    <DxCheckBox
                        :disabled="!QuyenTuyChonPhatHanhVe"
                        v-model:value="CheckPhatHanhVe"
                        text="Phát hành vé"
                        class="mr-3"
                    />
                    <DxCheckBox
                        :disabled="!QuyenTuyChonInVe"
                        v-model:value="CheckInVe"
                        text="In Vé"
                        class="mr-3"
                    />
                    <DxCheckBox
                        :disabled="!QuyenTuyChonIn2Lien"
                        v-model:value="CheckIn2Lien"
                        text="In 2 liên"
                    />
                </div>

                <div class="row mb-3">
                    <div class="xs12">
                        <ViDienTuVue
                            @ChonVi="
                                (item) => {
                                    ViDangChon = item;
                                }
                            "
                            :LayDanhSachVi="false"
                        />
                    </div>
                </div>
            </div>

            <div
                class="row mt-3 justify-end"
                style="
                    position: fixed;
                    bottom: 0;
                    border-top: 1px solid #dadce0;
                    left: 0;
                    padding: 12px;
                    background-color: #fff;
                    z-index: 9;
                "
            >
                <DxButton
                    text="XÁC NHẬN THANH TOÁN"
                    type="default"
                    styling-mode="contained"
                    style="width: 100%"
                    :disabled="!ViDangChon || TongTien == 0"
                    @click="BanVe()"
                />
            </div>
        </ion-content>

        <XuLyBanVeVue
            v-if="isXuLy"
            :state="state"
            :model="model"
            :msgError="msgError"
            @thulai="dongPopupXuLyBanVe"
            :thongTinThanhToan="thongTinThanhToan"
            type="KhongCoSoDo"
        />

        <PopupVue
            height="auto"
            v-if="ifPopupXacNhanBanVuotSoVeToiDa"
            v-model:dialog="PopupXacNhanBanVuotSoVeToiDa"
            heightScrollView="100%"
            title=""
            :useButtonLeft="false"
            :useButtonRight="false"
            class="popup-padding-16"
        >
            <template #content>
                <NotificationVue
                    :Params="ParamXacNhanBanVuotSoVeToiDa"
                    @click="XacNhanBanVuotSoVeToiDa"
                />
            </template>
        </PopupVue>
    </ion-page>
</template>

<script>
import { DxValidationGroup } from "devextreme-vue/validation-group";
import { DxTextBox } from "devextreme-vue/text-box";
import { IonContent, IonPage, IonToolbar, onIonViewWillEnter } from "@ionic/vue";
import {
    DxButton,
    DxSelectBox,
    DxValidator,
    DxCheckBox,
    DxNumberBox,
    DxRadioGroup,
    DxDateBox,
    DxTagBox,
} from "devextreme-vue";
import {
    DxEmailRule,
    DxNumericRule,
    DxRangeRule,
    DxRequiredRule,
    DxStringLengthRule,
    DxPatternRule,
} from "devextreme-vue/data-grid";
import ViDienTuVue from "../../../components/ViDienTu.vue";
import { mapMutations } from "vuex";
import XuLyBanVeVue from "../../../components/_Common/XuLyBanVe.vue";
import NotificationVue from "@sonphat/common-v1/components/Notification.vue";
import PopupVue from "@/components/_Common/Popup.vue";
export default {
    components: {
        DxTagBox,
        DxRadioGroup,
        DxStringLengthRule,
        DxPatternRule,
        DxNumberBox,
        DxNumericRule,
        DxRangeRule,
        DxRangeRule,
        DxCheckBox,
        DxValidationGroup,
        DxTextBox,
        IonContent,
        IonPage,
        DxButton,
        IonToolbar,
        DxSelectBox,
        DxEmailRule,
        DxValidator,
        DxTextBox,
        ViDienTuVue,
        DxRequiredRule,
        DxDateBox,
        XuLyBanVeVue,
        NotificationVue,
        PopupVue,
    },
    data() {
        return {
            rowDonGia: "row mb-1 align-center justify-space-between row-don-gia",
            rowThanhTien: "row mb-1 align-center justify-space-between row-thanh-tien",
            SoDoCho: [],
            HoVaTen: null,
            SoDienThoai: null,
            Email: null,
            CheckInVe: false,
            CheckIn2Lien: false,
            CheckPhatHanhVe: false,
            ViDangChon: null,
            SoLuongVe: 1,
            GiaVe: 0,
            GiaVeSauGiamTru: 0,
            queryHeader: {},
            phonePattern: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
            checkSuaGiaVe: false,
            TenCho: [],
            BienKiemSoat: null,
            GioXuatBenDuKien: null,
            state: "loading",
            isXuLy: false,
            msgError: null,
            model: {
                GiaVe: 0,
                GiaVeSauGiamTru: 0,
                SoDienThoai: null,
                Vi: {
                    tenVi: null,
                },
            },
            thongTinThanhToan: {},
            QuyenTuyChonPhatHanhVe: false,
            QuyenTuyChonInVe: false,
            QuyenTuyChonIn2Lien: false,
            ifPopupXacNhanBanVuotSoVeToiDa: false,
            PopupXacNhanBanVuotSoVeToiDa: false,
            ParamXacNhanBanVuotSoVeToiDa: {
                state: "Warning",
                title: "Thông báo!",
                message_title: "Bạn đã bán hết số vé tối đa được phép tại chuyến đi này!",
                message: "Bạn có muốn bán vượt số vé tối đa?",
            },
        };
    },
    computed: {
        reloadThongTinBanVe() {
            return this.$store.state.ChuyenDi.reloadThongTinBanVe;
        },
        ChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            return this.$store.state.DVVTLenh.ChiTietCauHinhDoanhNghiepTheoMaCauHinh;
        },
        SoLuongVeGuiDi() {
            let SoLuong = this.TenCho.length > 0 ? this.TenCho.length : this.SoLuongVe;
            return SoLuong;
        },
        TongTien() {
            return this.GiaVe * this.SoLuongVeGuiDi;
        },
        TongTienSauGiamTru() {
            return this.GiaVeSauGiamTru * this.SoLuongVeGuiDi;
        },
    },
    watch: {
        PopupXacNhanBanVuotSoVeToiDa() {
            if (!this.PopupXacNhanBanVuotSoVeToiDa) {
                setTimeout(() => {
                    this.ifPopupXacNhanBanVuotSoVeToiDa = false;
                }, 300);
            }
        },
    },
    methods: {
        ...mapMutations("ChuyenDi", ["Set"]),
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        onKeyUpSoDienThoai() {
            let IsValidSoDienThoai = this.$refs.refValidateSoDienThoai.instance.validate();
            this.isValidSoDienThoai = IsValidSoDienThoai.isValid;
        },
        async BanVe() {
            try {
                let validate = this.$refs.formValidation.instance.validate();
                if (validate.isValid) {
                    if (!this.ViDangChon.id) {
                        let rsKiemTraChuyenDi = await this.$store.dispatch(
                            "ChuyenDi/KiemTraTrangThaiChuyenDi",
                            {
                                IdChuyenDi: this.$route.query.guidChuyenDi,
                                SoKhach: this.SoLuongVeGuiDi,
                            },
                        );
                        if (!rsKiemTraChuyenDi.data) {
                            if (
                                this.$Helper.KiemTraQuyen(
                                    this.$t("QuyenBanVe.BanVeTaiBen"),
                                    this.$t("QuyenBanVe.BanVeVuotSoVeToiDa"),
                                ) &&
                                rsKiemTraChuyenDi.errorCode == 1268
                            ) {
                                this.showPopup("PopupXacNhanBanVuotSoVeToiDa");
                                return;
                            }
                            this.$Helper.ThongBaoPopup({
                                type: "Error",
                                message_title: "Bán vé gặp lỗi!",
                                message: rsKiemTraChuyenDi.message,
                            });
                            return;
                        }
                        this.ThanhToanTienMat();
                    }
                } else {
                    this.$Helper.autoFocusError(validate);
                }
                // let rs =
            } catch (error) {
                console.log("🚀 ~ error", error);
            }
        },
        XacNhanBanVuotSoVeToiDa(bool) {
            if (bool == true) {
                this.ThanhToanTienMat();
                this.PopupXacNhanBanVuotSoVeToiDa = false;
            } else if (bool == false) {
                this.PopupXacNhanBanVuotSoVeToiDa = false;
            }
        },
        async ThanhToanTienMat() {
            try {
                this.model = {
                    GiaVe: this.GiaVe,
                    GiaVeSauGiamTru: this.GiaVeSauGiamTru,
                    soDienThoai: this.soDienThoai,
                    Vi: {
                        tenVi: this.ViDangChon.tenVi,
                    },
                    SoLuong: this.SoLuongVeGuiDi,
                };
                this.isXuLy = true;
                this.state = "loading";
                let DanhSachChoNgoiBanVe = [];

                if (this.TenCho.length > 0) {
                    for (let i = 0; i < this.TenCho.length; i++) {
                        DanhSachChoNgoiBanVe.push({
                            idChoNgoi: "00000000-0000-0000-0000-000000000000",
                            giaVe: this.GiaVe,
                            giaVeSauGiamTru: this.GiaVeSauGiamTru || null,
                            tenCho: this.TenCho[i],
                        });
                    }
                } else {
                    for (let i = 0; i < this.SoLuongVeGuiDi; i++) {
                        DanhSachChoNgoiBanVe.push({
                            idChoNgoi: "00000000-0000-0000-0000-000000000000",
                            giaVe: this.GiaVe,
                            giaVeSauGiamTru: this.GiaVeSauGiamTru || null,
                            tenCho: "",
                        });
                    }
                }
                let query = {
                    guidChuyenDi: this.$route.query.guidChuyenDi,
                    danhSachChoNgoi: DanhSachChoNgoiBanVe,
                    hoTen: this.HoVaTen,
                    soDienThoai: this.SoDienThoai,
                    email: this.Email,
                    phatHanhVe: this.CheckPhatHanhVe,
                    idDiemXuatPhat: this.$route.query.idDiemXuatPhat,
                    tenDiemXuatPhat: this.$route.query.tenDiemXuatPhat,
                    idDiemDen: this.$route.query.idDiemDen,
                    tenDiemDen: this.$route.query.tenDiemDen,
                    bienKiemSoat: this.BienKiemSoat,
                    thoiGianXuatBen: this.GioXuatBenDuKien,
                };
                let rs = await this.$store.dispatch(
                    "NhanVienBanVe/ThucHienBanVeUyThacTienMat",
                    query,
                );
                if (rs.status) {
                    localStorage.setItem("TenCho", JSON.stringify(this.TenCho));
                    localStorage.setItem("CheckInVe", JSON.stringify(this.CheckInVe));
                    localStorage.setItem(
                        "CheckPhatHanhVe",
                        JSON.stringify(this.CheckPhatHanhVe),
                    );
                    localStorage.setItem(
                        "CheckIn2Lien",
                        JSON.stringify(this.CheckIn2Lien),
                    );
                    this.thongTinThanhToan = {
                        maGiaoDich: rs.data.maGiaoDich,
                        bienKiemSoat: this.BienKiemSoat || "",
                        gioXuatBenDuKien: new Date(this.GioXuatBenDuKien).toISOString(),
                        in2Lien: this.CheckIn2Lien,
                        hoTen: this.HoVaTen || "",
                        soDienThoai: this.SoDienThoai || "",
                    };
                    if (this.CheckInVe || this.CheckIn2Lien) {
                        this.state = "inVe";
                        // this.$router.push({
                        //     path: "/Ban-Ve/In-Ve",
                        //     query: {
                        //         maGiaoDich: rs.data.maGiaoDich,
                        //         bienKiemSoat: this.BienKiemSoat || "",
                        //         gioXuatBenDuKien: new Date(
                        //             this.GioXuatBenDuKien,
                        //         ).toISOString(),
                        //         in2Lien: this.CheckIn2Lien,
                        //         hoTen: this.HoVaTen || "",
                        //         soDienThoai: this.SoDienThoai || "",
                        //     },
                        // });
                    } else {
                        this.state = "success";
                    }
                } else {
                    this.state = "error";
                    this.msgError = rs.message;
                }
            } catch (error) {
                console.log("🚀 ~ error", error);
                this.state = "error";
                this.msgError = "Lỗi hệ thống!";
            }
        },
        getData() {
            this.queryHeader = this.$route.query;
            this.GiaVe = parseInt(this.queryHeader.giaVe);
            this.GiaVeSauGiamTru = parseInt(this.queryHeader.giaVeSauGiamTru);
            this.BienKiemSoat = this.queryHeader.bienKiemSoat;
            this.GioXuatBenDuKien = new Date(this.queryHeader.gioXuatBenDuKien);
            this.TenCho = [];
            this.SoLuongVe = 1;
            this.HoVaTen = null;
            this.SoDienThoai = null;
            this.Email = null;
        },
        dongPopupXuLyBanVe() {
            this.isXuLy = false;
        },
        KiemTraQuyenBanVe() {
            this.QuyenTuyChonPhatHanhVe = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.TuyChonPhatHanhVe"),
            );
            this.QuyenTuyChonInVe = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.TuyChonInVe"),
            );
            this.QuyenTuyChonIn2Lien = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.TuyChonIn2Lien"),
            );
            let BatBuocPhatHanhVe = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.BatBuocPhatHanhVe"),
            );
            let BatBuocInVe = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.BatBuocInVe"),
            );
            let BatBuocIn2Lien = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.BatBuocIn2Lien"),
            );
            let KhongChoPhepPhatHanhVe = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.KhongChoPhepPhatHanhVe"),
            );
            let KhongChoPhepInVe = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.KhongChoPhepInVe"),
            );
            let KhongChoPhepIn2Lien = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.KhongChoPhepIn2Lien"),
            );

            if (KhongChoPhepPhatHanhVe) {
                this.QuyenTuyChonPhatHanhVe = false;
                this.CheckPhatHanhVe = false;
            } else {
                if (BatBuocPhatHanhVe) {
                    this.QuyenTuyChonPhatHanhVe = false;
                    this.CheckPhatHanhVe = true;
                } else {
                    let CheckPhatHanhVe = localStorage.getItem("CheckPhatHanhVe");
                    this.CheckPhatHanhVe = !CheckPhatHanhVe
                        ? false
                        : CheckPhatHanhVe === "true";
                }
            }

            if (KhongChoPhepInVe) {
                this.QuyenTuyChonInVe = false;
                this.CheckInVe = false;
            } else {
                if (BatBuocInVe) {
                    this.QuyenTuyChonInVe = false;
                    this.CheckInVe = true;
                } else {
                    let CheckInVe = localStorage.getItem("CheckInVe");
                    this.CheckInVe = !CheckInVe ? false : CheckInVe === "true";
                }
            }

            if (KhongChoPhepIn2Lien) {
                this.QuyenTuyChonIn2Lien = false;
                this.CheckIn2Lien = false;
            } else {
                if (BatBuocIn2Lien) {
                    this.QuyenTuyChonIn2Lien = false;
                    this.CheckIn2Lien = true;
                } else {
                    let CheckIn2Lien = localStorage.getItem("CheckIn2Lien");
                    this.CheckIn2Lien = !CheckIn2Lien ? false : CheckIn2Lien === "true";
                }
            }
        },
        async LayChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            try {
                this.$startLoading;
                await this.$store.dispatch(
                    "DVVTLenh/LayChiTietCauHinhDoanhNghiepTheoMaCauHinh",
                    {
                        DanhSachMaCauHinhCanLay: [
                            this.$t(
                                "MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru",
                            ),
                        ],
                    },
                );
            } catch (error) {
                console.log("🚀 ~ error:", error);
            } finally {
                this.$stopLoading;
            }
        },
        onKeyUpTenCho(e) {
            let elmInput = e.element.querySelector("input");
            const eventEnter = new KeyboardEvent("keydown", {
                key: "Enter",
                keyCode: 13,
                bubbles: true,
                cancelable: true,
            });
            elmInput.addEventListener("keypress", function (keyPress) {
                var keynum = keyPress.keyCode || keyPress.which;
                if (keynum == 13 || keynum == 44 || keynum == 59) {
                    elmInput.dispatchEvent(eventEnter);
                    keyPress.preventDefault();
                }
            });
        },
        onCustomItemCreating(e) {
            if (!e.text) {
                e.customItem = null;
                return;
            }
            let checkTrung = this.TenCho.some((cho) => cho == e.text);
            if (checkTrung) {
                e.customItem = null;
                return;
            }
            e.customItem = e.text;
        },
    },
    created() {
        onIonViewWillEnter(() => {
            this.isXuLy = false;
            this.state = "loading";

            this.KiemTraQuyenBanVe();
            this.LayChiTietCauHinhDoanhNghiepTheoMaCauHinh();

            if (this.reloadThongTinBanVe) {
                this.getData();
                this.Set({ key: "reloadThongTinBanVe", data: false });
            }
            let keycloak = this.$Core.AuthApi.TokenParsed;
            this.checkSuaGiaVe = false;
            if (
                keycloak.hasOwnProperty("CauHinhBanVe") &&
                keycloak.CauHinhBanVe.hasOwnProperty("DuocPhepSuaGiaVeKhiBan")
            ) {
                this.checkSuaGiaVe = keycloak.CauHinhBanVe.DuocPhepSuaGiaVeKhiBan;
            }
        });
    },
};
</script>

<style scoped>
.row-don-gia {
    border-bottom: 1px solid #dadce0;
    padding: 4px 0;
}
.row-thanh-tien {
    padding: 6px 0 4px;
}
</style>
